import { useIntl } from 'react-intl-next';

import { useSessionData } from '@confluence/session-data';
import { UNSAFE_noExposureExp } from '@confluence/feature-experiments';

export const JIRA_PROJECT_BOARD_EXP_NAME = 'jira_project_board_in_confluence';
export const COHORT_KEY = 'cohort';
export const COHORT = {
	VARIATION: 'variation',
	CONTROL: 'control',
	NOT_ENROLLED: 'not-enrolled',
} as const;

export const useJiraProjectBoardExperiment = () => {
	const { locale } = useIntl();
	const { isLoggedIn } = useSessionData();

	const isEnglishLocale = (locale || '').toLowerCase().startsWith('en');

	if (!isEnglishLocale || !isLoggedIn) {
		return { isJiraProjectBoardExperimentEnabled: false, experimentCohort: COHORT.NOT_ENROLLED };
	}

	const [experiment] = UNSAFE_noExposureExp('jira_project_board_in_confluence');
	const experimentCohort = experiment.get<string>('cohort', COHORT.NOT_ENROLLED);

	const isJiraProjectBoardExperimentEnabled = experimentCohort === COHORT.VARIATION;

	return { isJiraProjectBoardExperimentEnabled, experimentCohort };
};
