import React, { useCallback } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl-next';
import { css } from '@compiled/react';

import { token } from '@atlaskit/tokens';
import { IconButton } from '@atlaskit/button/new';
import Heading from '@atlaskit/heading';
import MoreIcon from '@atlaskit/icon/glyph/more';
import { Flex, Inline } from '@atlaskit/primitives';
import Lozenge from '@atlaskit/lozenge';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import {
	AdControlDropdownMenu,
	DismissalButton,
	ReasonsButton,
} from '@atlassian/ad-control-toolkit';

import { Link } from '@confluence/route-manager';

const reasonsUlStyles = css({
	marginBlock: token('space.250', '20px'),
	paddingLeft: token('space.250', '20px'),
});

const i18n = defineMessages({
	tryLozenge: {
		id: 'experiment-jira-project-board.try-lozenge',
		defaultMessage: 'TRY',
		description: 'Title of lozenge that prompts an admin to try Jira',
	},
	newLozenge: {
		id: 'experiment-jira-project-board.new-lozenge',
		defaultMessage: 'NEW',
		description: 'Title of lozenge that prompts a user to try Jira',
	},
	dismissFlagTitle: {
		id: 'experiment-jira-project-board.dismiss-flag-title',
		defaultMessage: 'Got it, thanks for letting us know',
		description: 'Title of the flag that shows up when a user dismisses the placeholder template',
	},
	dismissFlagDescription: {
		id: 'experiment-jira-project-board.dismiss-flag-description',
		defaultMessage: 'You won’t see this here as often.',
		description:
			'Description of the flag that shows up when a user dismisses the placeholder template',
	},
	reasonsModalTitle: {
		id: 'experiment-jira-project-board.reasons-modal-title',
		defaultMessage: 'Why am I seeing this?',
		description: 'Title of the reasons modal',
	},
	reasonsModalExplanation: {
		id: 'experiment-jira-project-board.reasons-modal-explanation',
		defaultMessage:
			'We think <strong>Jira</strong> will improve your team’s productivity. The recommendation is based on:',
		description: 'Explanation as to why the placeholder template is being shown',
	},
	reasonsModalBulletOne: {
		id: 'experiment-jira-project-board.reasons-modal-bullet-one',
		defaultMessage: 'the size of your team',
		description: 'First bullet point of the reasons modal',
	},
	reasonsModalBulletTwo: {
		id: 'experiment-jira-project-board.reasons-modal-bullet-two',
		defaultMessage: 'your existing Atlassian products',
		description: 'Second bullet point of the reasons modal',
	},
	privacyPolicy: {
		id: 'experiment-jira-project-board.ad-controls.privacy-policy',
		defaultMessage: 'Learn more about your data privacy with Atlassian',
		description: "Link to Atlassian's privacy policy",
	},
});

type AdMenuProps = {
	onDismiss: () => Promise<void>;
	isSiteAdmin: boolean | undefined;
};

export const AdMenu = ({ onDismiss, isSiteAdmin }: AdMenuProps) => {
	return (
		<Inline alignBlock="center">
			<Flex gap="space.100" alignItems="center">
				<Lozenge appearance="new">
					{isSiteAdmin ? (
						<FormattedMessage {...i18n.tryLozenge} />
					) : (
						<FormattedMessage {...i18n.newLozenge} />
					)}
				</Lozenge>
				<AdControls onClickDismiss={onDismiss} />
			</Flex>
		</Inline>
	);
};

type AdControlsProps = {
	onClickDismiss: () => Promise<void>;
	menuButtonTestId?: string;
};

const AdControls = ({ onClickDismiss, menuButtonTestId }: AdControlsProps) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const onClickOpenReasons = useCallback(async () => {
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: 'adControlsReasons',
				source: 'JiraBoardNavigationAdControl',
			},
		}).fire();
	}, [createAnalyticsEvent]);

	return (
		<AdControlDropdownMenu
			itemId="jira-project-board-ad-controls"
			trigger={({ triggerRef, ...props }) => (
				<IconButton
					{...props}
					// we don't want to click the parent button too
					onClickCapture={(e) => e.preventDefault()}
					testId={menuButtonTestId}
					appearance="subtle"
					spacing="compact"
					ref={triggerRef}
					icon={() => <MoreIcon size="small" label="" />}
					label="More actions"
				/>
			)}
		>
			{(props) => (
				<>
					<DismissalButton
						{...props}
						flagProps={{
							title: <FormattedMessage {...i18n.dismissFlagTitle} />,
							description: <FormattedMessage {...i18n.dismissFlagDescription} />,
						}}
						onClick={onClickDismiss}
					/>
					<ReasonsButton
						{...props}
						onClick={onClickOpenReasons}
						modalProps={{
							title: (
								<Heading size="medium">
									<FormattedMessage {...i18n.reasonsModalTitle} />
								</Heading>
							),
							body: (
								<>
									<FormattedMessage
										{...i18n.reasonsModalExplanation}
										values={{
											strong: (chunks) => <strong>{chunks}</strong>,
										}}
									/>
									<ul css={reasonsUlStyles}>
										<li>
											<FormattedMessage {...i18n.reasonsModalBulletOne} />
										</li>
										<li>
											<FormattedMessage {...i18n.reasonsModalBulletTwo} />
										</li>
									</ul>
									<Link target="_blank" href="https://www.atlassian.com/legal/privacy-policy">
										<FormattedMessage {...i18n.privacyPolicy} />
									</Link>
								</>
							),
						}}
					/>
				</>
			)}
		</AdControlDropdownMenu>
	);
};
